import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Innovation = ({
}) => {



  return (
    <Fragment>



      <section id="innovation" className="diapo">



    

<div className="flexible">

<div class="column rightext">

      <img className="illustration" src={require('../../images/concept.png').default} />

      </div>

<div class="column rightext">
<h2>Un concept innovant</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse luctus tincidunt tortor ut tincidunt. Donec interdum, magna quis tempus luctus, urna neque auctor nulla, eget vestibulum massa sapien ac nibh. Sed eu tempor lectus. Proin suscipit ipsum ac nunc aliquet pellentesque. Quisque in neque non dolor dictum molestie. Aenean vitae auctor eros. Mauris et ex nibh.</p>
<br/><br/>
    <button>En savoir plus</button>
    </div>


     

   
      </div>


      </section>


     

    </Fragment>
  );
};

Innovation.propTypes = {
};

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  {}
)(Innovation);
