import React, { Fragment } from 'react';

import { connect } from 'react-redux';


const Header = ({
}) => {



  return (
    <Fragment>
            <header>



              <menu id="menudesktop">

       

                <img className="logo" src={require('../../images/header/logo.png').default} />

                <div className="space-menu"></div>

                <ul>

                  <li>Accueil</li>
                  <li>Templates <i class="arrow down"></i></li>
                  <li>Abonnement</li>
                  <li>Aide <i class="arrow down"></i></li>
                  <li id="selectlanguage">       <img className="mini-icons" src={require('../../images/icons/world.png').default} />Français <i class="arrow down"></i></li>
                  <li id="login-connect">  Se connecter</li>



                  </ul>

              </menu>


              
<menu id="menumobile">

     
  <input type="checkbox" id="openmenu" class="hamburger-checkbox"/>
  
  <div class="hamburger-icon">
    <label for="openmenu" id="hamburger-label">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </label>    
  </div>

    <div class="menu-pane">
      
      

      <nav>
        <ul class="menu-links">


                  <li>L'agence</li>
                  <li>Services</li>
                  <li>Templates</li>
                  <li>Tarifs</li>


     
        </ul>

      </nav>
    </div>




    </menu>

            </header>
    

    </Fragment>
  );
};

Header.propTypes = {
};

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  {  }
)(Header);
