import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Footer = ({
}) => {



  return (
    <Fragment>


      <footer>

   
    <div  class="flexible">
        <ul class="footer-menu">

                  <li><strong>Suivez-nous</strong></li>
                  <li>Instagram</li>
                  <li>Twitter</li>
                  <li>Snapchat</li>


                  </ul>

                  <ul class="footer-menu">

<li><strong>Société</strong></li>
<li>A propos</li>
<li>Nous contacter</li>
<li>Mentions légales</li>
<li>Politique de confidentialité</li>
<li>Conditions d'utilisation</li>
<li>CGV</li>

</ul>

<ul class="footer-menu">

<li><strong>Assistance</strong></li>
<li>Besoin d'aide</li>
<li>Guide d'utilisation</li>


</ul>


<ul class="footer-menu">

<li><strong>Produits</strong></li>
<li>Templates</li>
<li>Domaines</li>
<li>Hébergement</li>
<li>Fonctionnalités</li>
<li>Partenaires</li>


</ul>






     </div>

  

      <div class="copyright">Copyright @ 2022 AGE Inc. Tous droits réservés.</div>
      </footer>

    </Fragment>
  );
};

Footer.propTypes = {
};

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  {}
)(Footer);
