import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Social = ({
}) => {



  return (
    <Fragment>



    <ul class="social-media">
    <li><img className="icons" src={require('../../images/icons/insta.png').default} /></li>
    <li><img className="icons" src={require('../../images/icons/twitter.png').default} /></li>
    <li><img className="icons" src={require('../../images/icons/snap.png').default} /></li>
    
    </ul>



    </Fragment>
  );
};

Social.propTypes = {
};

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  {}
)(Social);
