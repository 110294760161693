import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Innovation = ({
}) => {



  return (
    <Fragment>



      <section id="price" className="diapo">



    






<div class="one-column ">
<h2 class="biggertitle">profitez d'un service sur mesure grâce à nos agences web partenaires</h2>
      <p>Integer a placerat mauris, a consectetur purus. Mauris ipsum ex, maximus ac tincidunt eu, egestas et eros. Pellentesque malesuada odio vitae quam viverra commodo. Mauris iaculis sit amet velit sed finibus. Vivamus sollicitudin vestibulum scelerisque. Pellentesque purus risus, congue et tortor nec, ultricies accumsan quam. Sed urna nulla, lobortis vitae blandit quis, commodo et ex. Nulla vitae nunc eu neque dignissim congue non vel nibh. Duis ultrices sapien at ipsum feugiat, vitae convallis urna lacinia. Donec a arcu ac eros molestie dictum ac eget ipsum. Pellentesque dolor velit, feugiat in vestibulum placerat, condimentum in mauris. Nulla pharetra risus ac sem posuere, ac tincidunt dolor lacinia. Morbi nec dui arcu. Duis hendrerit dolor auctor mauris blandit ullamcorper. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vitae finibus ipsum.</p>
<br/><br/>
    <button>En savoir plus</button>
   


     

   
      </div>


      </section>


     

    </Fragment>
  );
};

Innovation.propTypes = {
};

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  {}
)(Innovation);
