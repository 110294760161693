import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';


import './styles/desktop/header.css';
import './styles/desktop/main.css';
import './styles/desktop/footer.css';
import './styles/desktop/shortcode.css';
import './styles/desktop/form.css';
import './font/font.css';

import './styles/tablet/header.css';
import './styles/tablet/main.css';
import './styles/tablet/footer.css';

import './styles/mobile/header.css';
import './styles//mobile/main.css';
import './styles//mobile/footer.css';


import Footer  from './components/layout/Footer'
import Social  from './components/layout/Social'
import Header  from './components/layout/Header'
import Banner  from './components/homepage/Banner'
import Developpement  from './components/homepage/Developpement'
import Innovation  from './components/homepage/Innovation'
import Prices  from './components/homepage/Prices'


function App() {
  return (
    <div className="App">

<Header/>
<Social/>
    <main>

    <Banner/>
    <Developpement/>
    <Innovation/>
    <Prices/>



      </main>

     
      <Footer/>

    </div>
  );
}

export default App;
