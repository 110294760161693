import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Banner = ({
}) => {



  return (
    <Fragment>


      <section id="banner">


    <div lass="blackground"></div>



      <h1 className="bigtitle">Sublimez votre image en ligne.</h1>

      <p>Créez le site internet de vos envies simplement et gratuitement.<br></br>Plus de 500 templates pour un résultat professionnel.</p>

    <button>Créer</button>
      



      </section>


     

    </Fragment>
  );
};

Banner.propTypes = {
};

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  {}
)(Banner);
